import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { I18nextProvider, useTranslation } from 'react-i18next';
import './loader.css';
import { BeatLoader, PropagateLoader, ClockLoader } from 'react-spinners';
import { Helmet, HelmetProvider } from "react-helmet-async";

const countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo, Democratic Republic of the",
  "Congo, Republic of the",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, North",
  "Korea, South",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];
function Signup() {
    const baseUrl = process.env.REACT_APP_API_URL;
    const { t, i18n } = useTranslation();
    const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    email: "",
    pays: "",
    telephone: "",
    motDePasse: "",
    adresse: "",
    ville: "",
    code_postal: "",
    date_de_naissance: "",
  });

  const [loading, setLoading] = useState(false); // État pour le chargement

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Activer le chargement

    try {
      const response = await axios.post(
        "https://finaon.onrender.com/inscription",
        formData
      );
      console.log("Réponse de l'API :", response.data);

      // Affichage de la notification avec SweetAlert
      await Swal.fire({
        icon: "success",
        title: "Inscription réussie!",
        text: "Vous allez être redirigé vers la page de connexion.",
        timer: 5000, // Temps d'affichage en ms (5 secondes)
        timerProgressBar: true,
        showConfirmButton: false,
      });
      setTimeout(() => {
        window.location.href = `/${i18n.language}/login`;
      }, 1000);      
    } catch (error) {
      console.error("Erreur lors de l'envoi des données :", error);
      // Affichage d'une notification d'erreur avec SweetAlert
      await Swal.fire({
        icon: "error",
        title: "Erreur!",
        text: "Une erreur est survenue lors de l'inscription. Veuillez réessayer.",
      });
    } finally {
      setLoading(false); // Désactiver le chargement après l'exécution
    }
  };

  return (
    <HelmetProvider>

    <>
     <Helmet>
      <title>Inscrivez vous à finaon</title>
      <meta name="description" content="Laissez-vous inspirer par notre collection de meubles et décors. Chaque pièce est choisie pour apporter charme et fonctionnalité à votre intérieur, créant un espace où il fait bon vivre" />
    </Helmet>
        <section class="sign nb4-bg h-100 d-flex align-items-center position-relative z-0">
          <div class="animation position-absolute top-0 left-0 w-100 h-100 z-n1">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/star.png`}
              alt="vector"
              class="position-absolute push_animat"
            />
          </div>
          <div class="container ">
            <div class="row align-items-center justify-content-center justify-content-xl-start">
              <div class="col-12 col-sm-10 col-md-6">
                <div class="welcome alt-color text-center text-md-start pt-120 pb-120 position-relative z-0">
                  <h1 class="display-one">Content de te revoir!</h1>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-5 col-xxl-5 offset-xxl-1 text-center ms-xl-auto">
                <div class="sign__content ms-md-5 ms-xxl-0 pt-120 pb-120">
                  <div class="head_part">
                    <a href="index-2.html">
                      {" "}
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
                        alt="Logo"
                      />
                    </a>
                    <h5 class="mt-5 mt-lg-6">Inscrivez-vous à finaon</h5>
                  </div>
                  <form
                    onSubmit={handleSubmit}
                    class="contact__form mt-8 mt-lg-10  text-start"
                  >
                    <div class="d-flex flex-column gap-5 gap-lg-6 ">
                      <div class="row g-5 g-lg-6">
                        <div class="col-sm-6 col-md-12 col-xl-6">
                          <div class="single-input">
                            <label class="mb-2 nw1-color " for="fname">
                            Prénom
                            </label>
                            <input
                              type="text"
                              class="fs-six-up bg_transparent"
                              name="nom"
                              value={formData.nom}
                              onChange={handleChange}
                              placeholder="Name"
                              required
                            />
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-12 col-xl-6">
                          <div class="single-input">
                            <label class="mb-2 nw1-color" for="lname">
                            Nom de famille
                            </label>
                            <input
                              type="text"
                              class="fs-six-up bg_transparent"
                              name="prenom"
                              value={formData.prenom}
                              onChange={handleChange}
                              placeholder="Name"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div class="single-input">
                        <select
                          class="fs-six-up bg_transparent"
                          name="pays"
                          value={formData.pays}
                          onChange={handleChange}
                        >
                          <option class="fs-six-up bg_transparent" value="">
                          Sélectionnez votre pays
                          </option>
                          {countries.map((country) => (
                            <option key={country} value={country}>
                              {country}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div class="single-input">
                        <label class="mb-2 nw1-color" for="lname">
                          Telephone
                        </label>
                        <input
                          type="tel"
                          class="fs-six-up bg_transparent"
                          name="telephone"
                          value={formData.telephone}
                          onChange={handleChange}
                          placeholder="Phone Number"
                          required
                        />
                      </div>
                      <div class="single-input">
                        <label class="mb-2 nw1-color" for="lname">
                          Adresse
                        </label>
                        <input
                          type="text"
                          class="fs-six-up bg_transparent"
                          name="adresse"
                          value={formData.adresse}
                          onChange={handleChange}
                          placeholder="Adresse"
                          required
                        />
                      </div>
                      <div class="single-input">
                        <label class="mb-2 nw1-color" for="lname">
                        Ville
                        </label>
                        <input
                          type="text"
                          class="fs-six-up bg_transparent"
                          name="ville"
                          value={formData.ville}
                          onChange={handleChange}
                          placeholder="City"
                          required
                        />
                      </div>
                      <div class="single-input">
                        <label class="mb-2 nw1-color" for="lname">
                          Code Postal
                        </label>
                        <input
                          type="text"
                          class="fs-six-up bg_transparent"
                          name="code_postal"
                          value={formData.code_postal}
                          onChange={handleChange}
                          placeholder="Code Postale"
                          required
                        />
                      </div>
                      <div class="single-input">
                        <label class="mb-2 nw1-color" for="lname">
                          date_de_naissance
                        </label>
                        <input
                          type="date"
                          class="fs-six-up bg_transparent"
                          name="date_de_naissance"
                          value={formData.date_de_naissance}
                          onChange={handleChange}
                          placeholder="Code Postale"
                          required
                        />
                      </div>
                      <div class="single-input">
                        <label class="mb-2 nw1-color" for="password">
                        Mot de passe
                        </label>
                        <div class="input-pass">
                          <input
                            type="password"
                            class="fs-six-up bg_transparent pe-13"
                            name="motDePasse"
                            value={formData.motDePasse}
                            onChange={handleChange}
                            placeholder="Password"
                            required
                          />
                          <span class="password-eye-icon"></span>
                        </div>
                      </div>
                      <div class="single-input">
                        <label class="mb-2 nw1-color" for="cpassword">
                        Confirmez le mot de passe
                        </label>
                        <div class="input-pass">
                          <input
                            type="password"
                            class="fs-six-up bg_transparent pe-13"
                            name="cpassword"
                            id="cpassword"
                            placeholder="Confirmez le mot de passe"
                            required
                          />
                          <span class="password-eye-icon"></span>
                        </div>
                      </div>
                      <div class="single-input">
                        <label class="mb-2 nw1-color" for="email">
                          Email
                        </label>
                        <input
                          type="email"
                          class="fs-six-up bg_transparent"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          placeholder="Email Address"
                          required
                        />
                      </div>
                    </div>
                    <label class="checkbox-single d-flex align-items-center nw1-color mt-3">
                      <span class="checkbox-area d-center">
                        <input type="checkbox" />
                        <span class="checkmark d-center"></span>
                      </span>
                      J'accepte la politique de confidentialité
                    </label>
                    <div class=" mt-7 mt-lg-8">
                      <button
                        type="submit"
                        class="cmn-btn py-3 px-5 px-lg-6 mt-7 mt-lg-8 w-100 d-center"
                        name="submit"
                        id="submit"
                      >
                        S'inscrire
                      </button>
                    </div>
                  </form> <br/>
                  <div className="spinner-container">
                    <PropagateLoader color="#81e80e" loading={loading} size={20} />
                    </div>
                  <span class="or-option d-center w-100 mt-7 mt-lg-8 position-relative z-0">
                    <span class="px-3 nb4-bg">Or sign in with</span>
                  </span>
                  <div class="d-center gap-5 mt-7 mt-lg-8">
                    <a href="#">
                      <img src="assets/images/google.png" alt="Google logo" />
                    </a>
                    <a href="#">
                      {" "}
                      <img src="assets/images/facebook.png" alt="FB logo" />
                    </a>
                  </div>
                  <div class="mt-8 mt-lg-10">
                    <p>
                    Vous avez déjà un compte? <a href={`/${i18n.language}/login`}>Se connecter</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

    </>
    </HelmetProvider>

  );
}

export default Signup;
