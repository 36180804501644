import React, { useState, useEffect } from 'react';
import { 
  Container, Heading, VStack, Input, Button, Card, FormControl, FormLabel, Box, 
  Flex, Spacer, IconButton, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, 
  DrawerHeader, DrawerBody, useToast 
} from "@chakra-ui/react";
import { HamburgerIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { I18nextProvider, useTranslation } from 'react-i18next';

function Validation() {
  const [isOpen, setIsOpen] = useState(false);
  const [userId, setUserId] = useState('');
  const [code, setCode] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();
  const { t, i18n } = useTranslation();
  const handleLogout = () => {
    // Ajoutez votre logique de déconnexion ici
  };

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const utilisateurId = localStorage.getItem('utilisateurId');
        if (!utilisateurId) {
          throw new Error("ID utilisateur manquant dans le localStorage");
        }

        console.log("Récupération de l'ID utilisateur du localStorage:", utilisateurId);

        const response = await axios.get(`https://finaon.onrender.com/profile/${utilisateurId}`);
        console.log("Réponse de l'API:", response.data);

        if (response.data && response.data.utilisateur && response.data.utilisateur.id) {
          setUserId(response.data.utilisateur.id);
        } else {
          throw new Error("La réponse de l'API ne contient pas de champ 'id' dans 'utilisateur'");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération de l'ID utilisateur:", error);
        toast({
          title: "Erreur",
          description: `Une erreur s'est produite lors de la récupération de l'ID de l'utilisateur: ${error.message}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserId();
  }, [toast]);

  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`https://finaon.onrender.com/verifier-code/${userId}/code1`, {
        code1: code
      });
      setResponseMessage(response.data.message);
      toast({
        title: "Succès",
        description: response.data.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.href = `/${i18n.language}/loader`;
      }, 2000); 
    } catch (error) {
      setResponseMessage('Une erreur s\'est produite lors de la vérification du code.');
      console.error("Erreur lors de la vérification du code:", error);
      toast({
        title: "Erreur",
        description: 'Une erreur s\'est produite lors de la vérification du code.',
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      {/* Navbar */}
     

      {/* Drawer Menu */}
      

      {/* Main Content */}
      <Container maxW="container.md" mt="8" style={{ marginTop: '20%' }}>
        <Heading mb="4">Transfert</Heading>
        {isLoading ? (
          <Box>Chargement...</Box>
        ) : (
          <Card p="4"><br/><br/><br/><br/><br/>
            <form onSubmit={handleSubmit}>
              <VStack spacing="4">
                <FormControl>
                  <FormLabel>Veuillez Entré le code Validation: {userId || 'Non trouvé'}</FormLabel>
                  <Input type="text" value={code} onChange={handleCodeChange} placeholder="Enter code" />
                </FormControl>
                <Button type="submit" colorScheme="blue">Submit</Button>
              </VStack>
            </form>
            {responseMessage && (
              <Box mt="4" p="4" bg="gray.100" borderRadius="md">
                {responseMessage}
              </Box>
            )}
          </Card>
        )}
      </Container>
      <br /> {/* Ici */}

    </Box>
  );
}

export default Validation;
