import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { I18nextProvider, useTranslation } from 'react-i18next';
import { BeatLoader, PropagateLoader, ClockLoader, PulseLoader } from 'react-spinners';
import { Helmet, HelmetProvider } from "react-helmet-async";

function Login() {
const baseUrl = process.env.REACT_APP_API_URL;
  const { t, i18n } = useTranslation();
  const [formData, setFormData] = useState({
    email: "",
    motDePasse: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post("https://finaon.onrender.com/login", formData);
      const { token, utilisateurId } = response.data;

      localStorage.setItem("token", token);
      localStorage.setItem("utilisateurId", response.data.utilisateurId);

      Swal.fire({
        icon: "success",
        title: "Connexion réussie",
        text: "Vous serez redirigé vers le tableau de bord.",
      });

      setTimeout(() => {
        navigate(`/${i18n.language}/dashboards`);
        setIsLoading(false);
      }, 5000); // 5 secondes de délai avant la redirection
    } catch (error) {
      console.error("Erreur lors de l'envoi des données :", error);
      // Affichage d'une notification d'erreur avec SweetAlert
      await Swal.fire({
        icon: "error",
        title: "Erreur!",
        text: "Une erreur est survenue lors de la connexion. Veuillez réessayer.",
      });
    } finally {
      setIsLoading(false); // Désactiver le chargement après l'exécution
    }
  };

  return (
    <HelmetProvider>
    <>
    <Helmet>
          <title>Connectez-vous à finaon</title>
          <meta name="description" content="Laissez-vous inspirer par notre collection de meubles et décors. Chaque pièce est choisie pour apporter charme et fonctionnalité à votre intérieur, créant un espace où il fait bon vivre" />
        </Helmet>
<section class="sign nb4-bg h-100 d-flex align-items-center position-relative z-0">
          <div class="animation position-absolute top-0 left-0 w-100 h-100 z-n1">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/star.png`}
              alt="vector"
              class="position-absolute push_animat"
            />
          </div>
          <div class="container ">
            <div class="row align-items-center justify-content-center justify-content-xl-start">
              <div class="col-12 col-sm-10 col-md-6">
                <div class="welcome alt-color text-center text-md-start pt-120 pb-120 position-relative z-0">
                  <h1 class="display-one">Content de te revoir!</h1>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-5 col-xxl-5 offset-xxl-1 text-center ms-xl-auto mx-auto">
                <div class="sign__content ms-md-5 ms-xxl-0 pt-120 pb-120">
                  <div class="head_part">
                    <a href="index-2.html">
                      {" "}
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
                        alt="Logo"
                      />
                    </a>
                    <h5 class="mt-5 mt-lg-6">Connectez-vous à votre compte</h5>
                  </div>
                  <form
                    onSubmit={handleSubmit}
                    class="contact__form mt-8 mt-lg-10 text-start"
                  >
                    <div class="d-flex flex-column gap-5 gap-lg-6 ">
                      <div class="single-input">
                        <label class="mb-2 nw1-color" for="lname">
                          Email
                        </label>
                        <input
                          type="text"
                          class="fs-six-up bg_transparent"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          placeholder="Email"
                          required
                        />
                      </div>
                      <div class="single-input">
                        <label class="mb-2 nw1-color" for="password">
                        Mot de passe
                        </label>
                        <div class="input-pass">
                          <input
                            type="password"
                            class="fs-six-up bg_transparent pe-13"
                            name="motDePasse"
                            value={formData.motDePasse}
                            onChange={handleChange}
                            placeholder="Password"
                            required
                          />
                          <span class="password-eye-icon"></span>
                        </div>
                      </div>
                    </div>
                    <div class="mt-3 d-flex align-items-center justify-content-between gap-10 flex-wrap">
                      <label class="checkbox-single d-flex align-items-center nw1-color">
                        <span class="checkbox-area d-center">
                          <input type="checkbox" />
                          <span class="checkmark d-center"></span>
                        </span>
                        Souviens-toi de moi
                      </label>
                      <a href="#" class="nw1-color">
                      Mot de passe oublié?
                      </a>
                    </div>
                    <div class=" mt-7 mt-lg-8">
                      <button
                        type="submit"
                        class="cmn-btn py-3 px-5 px-lg-6 mt-7 mt-lg-8 w-100 d-center"
                        name="submit"
                        id="submit"
                      >
                        Se connecter
                      </button>
                    </div>
                  </form><br/>
                  <div className="spinner-container">
                    <PropagateLoader color="#81e80e" loading={isLoading} size={20} />
                    </div>
                  <span class="or-option d-center w-100 mt-7 mt-lg-8 position-relative z-0">
                    <span class="px-3 nb4-bg">Ou connectez-vous avec</span>
                  </span>
                  <div class="d-center gap-5 mt-7 mt-lg-8">
                    <a href="#">
                      <img src="assets/images/google.png" alt="Google logo" />
                    </a>
                    <a href="#">
                      {" "}
                      <img src="assets/images/facebook.png" alt="FB logo" />
                    </a>
                  </div>
                  <div class="mt-8 mt-lg-10">
                    <p>
                    Vous n’avez pas de compte ? <a href={`/${i18n.language}/signup`}>Inscrivez-vous ici</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    </>
    </HelmetProvider>

  );
}

export default Login;
