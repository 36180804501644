import React, { useState, useEffect } from 'react';
import './Loader-copy.css'; // Assurez-vous d'avoir un fichier CSS pour styliser votre loader, vous pouvez le personnaliser selon vos besoins
import { I18nextProvider, useTranslation } from 'react-i18next';

const Loader3 = () => {
  const [progress, setProgress] = useState(0);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prevProgress => (prevProgress >= 100 ? 0 : prevProgress + 1)); // Augmentation lente de 1% à chaque intervalle
    }, 1000); // Augmentez l'intervalle à 1000ms (1 seconde) pour ralentir le chargement

 // Redirection après 20 secondes
 const redirectTimeout = setTimeout(() => {
    // Remplacez '/destination' par l'URL de la page vers laquelle vous voulez rediriger
    window.location.href = `/${i18n.language}/Doucment`;
  }, 100000); // 20 secondes

  return () => {
    clearInterval(interval);
    clearTimeout(redirectTimeout);
  };
}, []);

  return (
    <div className="loader-container">
      <h2>Chargement en cours...</h2><br/>
      <div className="loader">
        <div className="progress" style={{ width: `${progress}%` }}>
          {progress}%
        </div>
      </div>
    </div>
  );
};

export default Loader3;
