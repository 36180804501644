import React, { useState } from 'react';
import { Outlet, Link } from "react-router-dom";

import { useTranslation } from 'react-i18next';


function AdminLayout({children}){
    const { t, i18n } = useTranslation();

    return(
        <>
        <div class="mouse-follower">
        <span class="cursor-outline"></span>
        <span class="cursor-dot"></span>
    </div>
    <button class="scrollToTop d-none d-md-flex d-center rounded" aria-label="scroll Bar Button"><i class="mat-icon fs-four nb4-color ti ti-arrow-up"></i></button>

        <header class="header-section a2-bg header-menu w-100">
        <div class="container  d-center ">
           <nav class="navbar py-2 py-xl-4 rounded-3 navbar-expand-lg w-100 justify-content-between ">
                <div class="d-flex align-items-center py-3">
                    <button class="navbar-toggler ms-4" type="button" data-bs-toggle="collapse" aria-label="Navbar Toggler"
                    data-bs-target="#navbar-content" aria-expanded="true" id="nav-icon3">
                        <span></span><span></span><span></span><span></span>
                    </button>
                    <a href="index-2.html" class="navbar-brand m-0 p-0 d-flex align-items-center gap-5 gap-xl-5 me-2">
                        <img src="assets/images/fav.png" class="logo small_logo d-sm-none" alt="logo"/>
                        <img src="assets/images/logo.png" class="logo d-none d-sm-flex" alt="logo"/>
                    </a>
                </div>
                <div class="nav_alt">
                    <div class="right-area position-relative ms-0 d-center gap-1 gap-xl-4 d-lg-none">
                        <div class="single-item">
                            <Link to={`/${i18n.language}/login`} class="rotate_eff flex-nowrap py-1 px-2 px-xl-3 d-center gap-1 fw-bold nw1-color"> Login <i class="ti ti-arrow-right fs-six-up"></i></Link>
                        </div>
                        <div class="single-item">
                            <Link to={`/${i18n.language}/signup`} class="cmn-btn fw-bold py-2 px-2 px-sm-3 px-lg-4 align-items-center gap-1"> Sign Up <i class="ti ti-arrow-right fw-semibold fs-six-up"></i></Link>
                        </div>
                    </div>
                </div>
                <div class="collapse navbar-collapse justify-content-center">
                    <ul class="navbar-nav gap-2 gap-lg-3 gap-xxl-8  align-self-center mx-auto mt-4 mt-lg-0">
                        <li class="dropdown show-dropdown"><Link to="/">
                            <button type="button" aria-label="Navbar Dropdown Button"
                                >home</button></Link>
                           
                        </li>
                        <li class="dropdown show-dropdown"><Link to={`/${i18n.language}/about`}>
                            <button type="button" aria-label="Navbar Dropdown Button"
                                >About</button></Link>
                           
                        </li>
                        <li class="dropdown show-dropdown"><Link to={`/${i18n.language}/contact`}>
                            <button type="button" aria-label="Navbar Dropdown Button"
                                >Contact</button></Link>
                            
                        </li>
                        <li class="dropdown show-dropdown"><Link to={`/${i18n.language}/requestloan`}>
                            <button type="button" aria-label="Navbar Dropdown Button"
                                >Request Loan</button></Link>
                            
                        </li>
                       
                        
                    </ul>
                </div>
                <div class="right-area position-relative  ms-0 d-center gap-1 gap-xl-4 d-none d-lg-flex">
                    <div class="single-item">
                        <Link to={`/${i18n.language}/login`} class="cmn-btn fw-bold py-2 px-2 px-sm-3 px-lg-4 align-items-center gap-1"> Deconnecter <i class="ti ti-arrow-right fw-semibold fs-six-up"></i></Link>
                    </div>
                </div>
            </nav>
        </div>
    </header>
        
    <main>{children}</main>


        </>
    )
}

export default AdminLayout;