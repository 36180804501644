import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  Container, Heading, VStack, Input, Button, Card, FormControl, FormLabel, Box, 
  Flex, Spacer, IconButton, Drawer, DrawerOverlay, DrawerContent, 
  DrawerCloseButton, DrawerHeader, DrawerBody, useToast 
} from "@chakra-ui/react";
import { HamburgerIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import { I18nextProvider, useTranslation } from 'react-i18next';

function Transfert() {
  const [toUserId, setToUserId] = useState('');
  const [amount, setAmount] = useState('');
  const [beneficiaire, setBeneficiaire] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const toast = useToast();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`https://finaon.onrender.com/profile/${localStorage.getItem('utilisateurId')}`);
        setAccountNumber(response.data.utilisateur.account_number);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []); 

  const handleLogout = () => {
    // Ajoutez votre logique de déconnexion ici
  };

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const validateInputs = () => {
    if (!toUserId || !amount || !beneficiaire) {
      toast({
        title: "Erreur",
        description: "Tous les champs doivent être remplis.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return false;
    }
    if (isNaN(amount) || amount <= 0) {
      toast({
        title: "Erreur",
        description: "Le montant doit être un nombre positif.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateInputs()) {
      return;
    }

    const fromUserId = accountNumber;

    try {
      const response = await axios.post(`https://finaon.onrender.com/transferts/${fromUserId}`, {
        to_user_id: toUserId,
        amount: parseFloat(amount),
        beneficiaire: beneficiaire
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      console.log('Response:', response.data);

      toast({
        title: "Transfert réussi",
        description: "Le transfert a été effectué avec succès.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.href = `/${i18n.language}/verifytransfert`;
      }, 4000); 
    } catch (error) {
      console.error('Error:', error);
      toast({
        title: "Erreur",
        description: "Une erreur est survenue lors du transfert.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      

      

      <Container maxW="container.md" mt="8" style={{ marginTop: '10%' }}>
        <Heading mb="4"></Heading>
        <Card>
          <form onSubmit={handleSubmit}>
            <VStack spacing="4" p="4">
              <FormControl>
                <FormLabel>Titulaire du compte</FormLabel>
                <Input type="text" value={beneficiaire} onChange={(e) => setBeneficiaire(e.target.value)} />
              </FormControl>
              <FormControl>
                <FormLabel>Montant</FormLabel>
                <Input type="text" value={amount} onChange={(e) => setAmount(e.target.value)} />
              </FormControl>
              <FormControl>
                <FormLabel>IBAN / NUMERO DE COMPTE</FormLabel>
                <Input type="text" value={toUserId} onChange={(e) => setToUserId(e.target.value)} />
              </FormControl>
              <Button type="submit" colorScheme="blue">Submit</Button>
            </VStack>
          </form>
        </Card>
      </Container>
      <br /> {/* Ici */}
    </Box>
  );
}

export default Transfert;
