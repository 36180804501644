import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Importation de Bootstrap CSS
import './FAQ.css'; // Importation des styles personnalisés
import { useTranslation } from 'react-i18next';
const FAQ = () => {
    const { t } = useTranslation();
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const faqs = t('faq.questions', { returnObjects: true });

    return (
        <section className="faq a2-bg pb-120 pt-120 position-relative z-0">
            <div className="animation vector position-absolute top-0 left-0 w-100 h-100 z-n1">
                <img src={`${process.env.PUBLIC_URL}/assets/images/button.png`} alt="vector" className="position-absolute pt-6 pt-xl-15 previewShapeRevX d-none d-md-flex"/>
                <img src={`${process.env.PUBLIC_URL}/assets/images/star2.png`} alt="vector" className="position-absolute push_animat end-0 top-0 mt-20 pt-5 me-xl-20 pe-5 d-none d-md-flex"/>
                <img src={`${process.env.PUBLIC_URL}/assets/images/vector20.png`} alt="vector" className="position-absolute bottom-0 start-0 ps-8 pb-10 d-none d-xxxl-flex"/>
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-xxl-7">
                        <div className="heading__content mb-10 mb-lg-15 text-center">
                            <span className="heading fs-five p1-color mb-5">Faq’s</span>
                            <h3 style={{color: 'white'}}> {t('faq2.questions')} </h3>
                        </div>
                    </div>
                </div>
                <div className="row gy-10 justify-content-center align-items-center">
                    <div className="col-md-12 col-lg-7 col-xxl-6">
                        <div className="faq__part">
                            <div className="accordion-section d-grid gap-6">
                                {faqs.map((faq, index) => (
                                    <div key={index} className={`accordion-single cus-rounded-1 nb3-bg box-shadow py-3 py-md-4 px-4 px-md-5 ${activeIndex === index ? 'active' : ''}`}>
                                        <h5 className="header-area">
                                            <button  style={{color: 'black'}}
                                                className="accordion-btn transition fw-semibold text-start d-flex position-relative w-100" 
                                                type="button"
                                                onClick={() => toggleAccordion(index)}
                                            >
                                                {faq.question}
                                            </button>
                                        </h5>
                                        <div className={`content-area ${activeIndex === index ? 'show' : ''}`}>
                                            <div className="content-body pt-5">
                                                <p style={{color: 'black'}}>{faq.answer}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-9 col-sm-8 col-lg-5 col-xxl-6">
                        <div className="faq_thumbs d-flex justify-content-center justify-content-xl-end">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/faq.png`} alt="image"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FAQ;
