import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import './CookieConsent.css';

const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(['userConsent']);
  const [isVisible, setIsVisible] = useState(!cookies.userConsent);

  const handleAccept = () => {
    setCookie('userConsent', true, { path: '/', maxAge: 31536000 }); // Cookie lasts for 1 year
    setIsVisible(false);
  };

  useEffect(() => {
    if (cookies.userConsent) {
      setIsVisible(false);
    }
  }, [cookies]);

  if (!isVisible) return null;

  return (
    <div className="cookie-consent">
      <div className="cookie-consent__content">
        <p>
        Le site contient des cookies Ils nous aident à gérer le site, à améliorer ton expérience et à personnaliser les publicités que nous t'affichons.
        </p>
        <button onClick={handleAccept} className="cookie-consent__button">Accepter</button>
      </div>
    </div>
  );
};

export default CookieConsent;
