// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationFR from './locales/fr/translation.json';
import translationNL from './locales/nl/translation.json';
import translationDE from './locales/de/translation.json';
import translationPT from './locales/pt/translation.json';
import translationRO from './locales/ro/translation.json';
import translationSK from './locales/sk/translation.json';
import translationBJ from './locales/bj/translation.json';
import translationTR from './locales/tr/translation.json';
import translationGR from './locales/gr/translation.json';
import translationIT from './locales/it/translation.json';
import translationES from './locales/es/translation.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: translationEN },
      fr: { translation: translationFR },
      nl: { translation: translationNL },
      de: { translation: translationDE },
      pt: { translation: translationPT },
      bj: { translation: translationBJ },
      ro: { translation: translationRO },
      sk: { translation: translationSK },
      tr: { translation: translationTR },
      gr: { translation: translationGR },
      it: { translation: translationIT },
      es: { translation: translationES }
    },
    lng: 'fr', // Langue par défaut en français
    fallbackLng: 'fr', // Langue de secours en français
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
