import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box, Flex, Text,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
} from '@chakra-ui/react';

const LoanRequests = () => {
  const [loanRequests, setLoanRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchLoanRequests();
  }, []);

  const fetchLoanRequests = async () => {
    try {
      const response = await axios.get('https://finaon.onrender.com/api/loan-requests');
      setLoanRequests(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Erreur lors de la récupération des demandes de prêt :', error);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Flex justify="center" align="center" h="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Box p="8"><br/><br/><br/><br/><br/><br/><br/><br/>
      <Heading mb="4">Demandes de Prêt</Heading>
      <Table variant="simple" css={`
      width: 100%;
      border-collapse: collapse;

      th {
        background-color: #f1f1f1;
        font-weight: bold;
        text-align: left;
        padding: 8px;
      }

      td {
        padding: 8px;
        border-bottom: 1px solid #cccccc;
      }

      tr:last-child td {
        border-bottom: none;
      }
    `}>
        <Thead>
          <Tr>
            <Th>Nom et prénom</Th>
            <Th>Email</Th>
            <Th>Telephone</Th>
            <Th>Montant du prêt</Th>
          </Tr>
        </Thead>
        <Tbody>
          {loanRequests.map((request, index) => (
            <Tr key={index}>
              <Td>{request.full_name}</Td>
              <Td>{request.email}</Td>
              <Td>{request.phone}</Td>
              <Td>{request.loan_amount}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default LoanRequests;
