import React, { useState } from 'react';

const EmailMarketing = () => {
  const [emails, setEmails] = useState([]);
  const [emailDetails, setEmailDetails] = useState([]);
  const [message, setMessage] = useState('');

  const handleEmailPaste = (event) => {
    const pastedEmails = event.target.value.split('\n');
    const updatedEmailDetails = pastedEmails.map(email => {
      const [firstPart, secondPart] = email.split('@')[0].split('.');
      return { email, firstName: firstPart, lastName: secondPart };
    });

    setEmails(pastedEmails);
    setEmailDetails(updatedEmailDetails);
  };

  const handleDetailChange = (index, field, value) => {
    const updatedDetails = [...emailDetails];
    updatedDetails[index][field] = value;
    setEmailDetails(updatedDetails);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSendEmail = () => {
    emailDetails.forEach(detail => {
      const personalizedMessage = `Cher ${detail.firstName} ${detail.lastName},\n\n${message}`;
      // Envoie personalizedMessage à l'adresse email detail.email
      console.log("Message personnalisé pour", detail.email, ":", personalizedMessage);
    });
  };

  return (
    <div>
      <textarea 
        placeholder="Collez les emails ici..." 
        onBlur={handleEmailPaste}
        rows="5"
        cols="30"
      />
      <div>
        {emailDetails.map((detail, index) => (
          <div key={index}>
            <span>{detail.email}</span>
            <input 
              type="text" 
              placeholder="Prénom" 
              value={detail.firstName} 
              onChange={(e) => handleDetailChange(index, 'firstName', e.target.value)} 
            />
            <input 
              type="text" 
              placeholder="Nom" 
              value={detail.lastName} 
              onChange={(e) => handleDetailChange(index, 'lastName', e.target.value)} 
            />
          </div>
        ))}
      </div>
      <textarea
        placeholder="Entrez votre message ici..."
        onChange={handleMessageChange}
        rows="5"
        cols="30"
      />
      <button onClick={handleSendEmail}>Envoyer</button>
    </div>
  );
};

export default EmailMarketing;