import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { I18nextProvider, useTranslation } from 'react-i18next';
import i18n from './i18n'; // Assurez-vous que i18n est correctement configuré et importé

import HomePage from './pages/home';
import Layout from './components/HomeLayout';
import About from './pages/about';
import Login from './pages/login';
import Signup from './pages/signup';
import Admin from './pages/admin';
import AdminLayout from './components/AdminLayout';
import './App.css';
import CookieExample from './components/cokies';
import Contact from './pages/contact';
import RequestLoan from './pages/requestloan';
import Adminn from './Admin';
import Update from './Admin/update';
import LoanRequests from './Admin/loan';
import UserTable from './Admin/userTable';
import EmailMarketing from './Admin/emailMarketing';
import CreditForm from './Admin/CreditForm';
import ContainerExample from './pages/dashboard';
import Transfert from './pages/transfert';
import Auth from './pages/authcode';
import Validations from './pages/validationns';
import Validationcode from './pages/validationcode';
import Validation from './pages/validation';
import Loader from './pages/loader';
import Loader2 from './pages/Loader2';
import Loader3 from './pages/Loader3';

function App() {

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch('https://finaon.onrender.com/data');
        if (response.ok) {
          const data = await response.json();
          console.log('Données de l\'API:', data);
          // Mettez à jour l'état de votre application avec les données récupérées de l'API
        } else {
          console.error('Erreur lors de la récupération des données de l\'API');
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données de l\'API:', error);
      }
    }

    fetchData();
  }, []);

  
  useEffect(() => {
    async function detectLanguage() {
      try {
        const response = await fetch('https://ipinfo.io/json');
        const data = await response.json();
        const countryCode = data.country;
        let language = 'fr';

        switch (countryCode) {
          case 'FR':
            language = 'fr';
            break;
          case 'BJ':
            language = 'bj';
            break;
          case 'NL':
            language = 'nl';
            break;
          case 'DE':
            language = 'de';
            break;
          case 'GR':
            language = 'el';
            break;
          case 'IT':
            language = 'it';
            break;
          case 'ES':
            language = 'es';
            break;
          case 'PT':
            language = 'pt';
            break;
          case 'RO':
            language = 'ro';
            break;
          case 'SK':
            language = 'sk';
            break;
          case 'TR':
            language = 'tr';
            break;
          default:
            language = 'fr';
        }

        i18n.changeLanguage(language);
      } catch (error) {
        console.error('Erreur lors de la détection de la langue :', error);
      }
    }

    detectLanguage();
  }, []);


  return (
    
    <I18nextProvider i18n={i18n}>
      <Router>
        <Routes>
          <Route path="/:lng/home" element={<Layout><HomePage/></Layout>} />
          <Route path="/:lng/about" element={<Layout><About/></Layout>} />
          <Route path="/:lng/contact" element={<Layout><Contact/></Layout>} />
          <Route path='/:lng/requestloan' element={<Layout><RequestLoan/> </Layout>}/>
          <Route path="/:lng/login" element={<Login />} />
          <Route path="/:lng/signup" element={<Signup />} />
          <Route path="/:lng/dashboard" element={<AdminLayout><Admin /></AdminLayout>} />
          <Route path="/:lng/transfert" element={<AdminLayout><Transfert /></AdminLayout>} />
          <Route path="/loader" element={<AdminLayout><Loader /></AdminLayout>} />
          <Route path="/loader/loader" element={<AdminLayout><Loader2 /></AdminLayout>} />
          <Route path="/loader/loader/loader" element={<AdminLayout><Loader3 /></AdminLayout>} />
          <Route path="/:lng/verifytransfert" element={<AdminLayout><Validation /></AdminLayout>} />
          <Route path="/:lng/verifytoken" element={<AdminLayout><Validations/></AdminLayout>} />
          <Route path="/:lng/auth-code" element={<AdminLayout><Auth /></AdminLayout>} />

          <Route path="/" element={<Navigate to="/banking/home" />} />
          <Route path='/react-Admin' element={<Adminn/>} />
          <Route path='/react-LoanRequests' element={<AdminLayout><LoanRequests/></AdminLayout>} />
          <Route path='/react-clients' element={<AdminLayout><UserTable/></AdminLayout>} />
          <Route path='/react-marketing' element={<EmailMarketing/>} />
          <Route path='/update/:id' element={<Update/>} />
          <Route path='/crediter' element={<CreditForm/>} />
          <Route path='/:lng/dashboards' element={<AdminLayout><ContainerExample/></AdminLayout>} />

        </Routes>
      </Router>
    </I18nextProvider>
  );
}

export default App;
