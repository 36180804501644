import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Container, Navbar, Nav, Row, Col, Card, NavDropdown,Table, Button, Form, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faSms, faHome, faUser, faChartBar, faCreditCard, faMoneyCheckAlt, faCog } from '@fortawesome/free-solid-svg-icons';
import './Admin.css'; // Importez votre fichier CSS personnalisé pour le style
import ReactApexChart from "react-apexcharts"; // Importez le composant de graphique ApexCharts

function Adminn() {
  const authorizationToken = process.env.REACT_APP_AUTHORIZATION;
  const [totalAmount, setTotalAmount] = useState(null);
  const [totalUtilisateurs, setTotalUtilisateurs] = useState(null);
  const [utilisateurs, setUtilisateurs] = useState([]);
  const [error, setError] = useState(null);
  const [smsBalance, setSmsBalance] = useState(0); // Remplacez 0 par la valeur initiale appropriée
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    shop: '6474528eda121f5647d4fc1d',
    amount: '',
    phoneNumber: '',
  });

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const sendRequest = async (event) => {
    event.preventDefault();


    try {
      // Envoyer la demande initiale
      const response = await axios.post('https://api.feexpay.me/api/transactions/public/requesttopay/mtn', {
        shop: formData.shop,
        amount: formData.amount,
        phoneNumber: formData.phoneNumber,
      }, {
        headers: {
          'Authorization': authorizationToken,
        },
      });

      console.log('Réponse initiale:', response.data);

      const reference = response.data.reference;

      // Vérifier le statut de paiement à intervalles réguliers
      const intervalId = setInterval(() => {
        checkPaymentStatus(reference, intervalId);
      }, 5000); // Vérifier toutes les 5 secondes (ajustez si nécessaire)

    } catch (error) {
      console.error('Erreur lors de la requête initiale:', error);
      // Gestion d'erreur : afficher un message d'erreur ou logguer pour débogage
    }
  };

  const checkPaymentStatus = async (reference, intervalId) => {

    try {
      // Vérifier le statut du paiement
      const response = await axios.get(`https://api.feexpay.me/api/transactions/public/single/status/${reference}`, {
        headers: {
          'Authorization': authorizationToken,
        },
      });

      console.log('Statut de paiement:', response.data);

      const status = response.data.status;

      if (status === "SUCCESSFUL" || status === "FAILED") {
        clearInterval(intervalId); // Arrêter la vérification à intervalles
        // Envoyer la requête de recharge
        const rechargeResponse = await axios.post('https://backend-zs1h.onrender.com/recharge', {
          user_id: formData.shop,
          phone_number: formData.phoneNumber,
          amount: formData.amount
        });

        console.log('Réponse de recharge:', rechargeResponse.data);

        // Redirection ou autre action après la recharge réussie
        window.location.href = "https://lavedette.net";
      } else {
        console.error("Statut de paiement inconnu :", status);
      }

    } catch (error) {
      console.error('Erreur lors de la vérification du statut de paiement:', error);
      // Gestion d'erreur : afficher un message d'erreur ou logguer pour débogage
    }
  };
  

  useEffect(() => {
    const fetchTotalUtilisateurs = async () => {
      try {
        const response = await axios.get('https://finaon.onrender.com/admin/utilisateurs/total');
        setTotalUtilisateurs(response.data.total);
      } catch (error) {
        console.error("Erreur lors de la récupération du nombre total d'utilisateurs:", error);
        setError("Erreur lors de la récupération du nombre total d'utilisateurs.");
      }
    };

    fetchTotalUtilisateurs();
  }, []);

  useEffect(() => {
    const fetchUtilisateurs = async () => {
      try {
        const response = await axios.get('https://finaon.onrender.com/admin/utilisateurs');
        setUtilisateurs(response.data.utilisateurs);
      } catch (error) {
        console.error("Erreur lors de la récupération des informations des utilisateurs:", error);
        setError("Erreur lors de la récupération des informations des utilisateurs.");
      }
    };

    fetchUtilisateurs();
  }, []);

  useEffect(() => {
    const fetchTotalAmount = async () => {
      try {
        const response = await fetch('https://finaon.onrender.com/totaletransfers');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setTotalAmount(data.total_amount);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchTotalAmount();
  }, []);

  if (error) {
    return <div>Erreur : {error}</div>;
  }

  if (totalAmount === null) {
    return <div>Chargement...</div>;
  }

  const chartOptions = {
    chart: {
      type: 'bar'
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']
    },
    title: {
      text: 'Total Transferts par mois',
      align: 'center',
      margin: 20,
      offsetY: 20,
      style: {
        fontSize: '20px'
      }
    },
    legend: {
      position: 'right'
    }
  };

  const chartSeries = [{
    name: "Total Transferts",
    data: [65, 59, 80, 81, 56, 55, 40]
  }];

  return (
    <>
      <Navbar bg="primary" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand href="#home">Finaon Admin</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </Container>
      </Navbar>

      <div className="sidebar">
      <Nav className="flex-column">
        <Nav.Link href="#home" className="sidebar-link"><FontAwesomeIcon icon={faHome} /> Accueil</Nav.Link>
        <Nav.Link href="#profile" className="sidebar-link"><FontAwesomeIcon icon={faUser} /> Profil</Nav.Link>
        <NavDropdown title={<span><FontAwesomeIcon icon={faChartBar} /> Statistiques</span>} id="basic-nav-dropdown" className="sidebar-link">
          <NavDropdown.Item href="#transactions">Transactions</NavDropdown.Item>
          <NavDropdown.Item href="/react-clients">Clients</NavDropdown.Item>
          <NavDropdown.Item href="/react-LoanRequests">Prêts</NavDropdown.Item>
        </NavDropdown>
        <Nav.Link href="#cards" className="sidebar-link"><FontAwesomeIcon icon={faCreditCard} /> Cartes</Nav.Link>
        <Nav.Link href="/react-LoanRequests" className="sidebar-link"><FontAwesomeIcon icon={faMoneyCheckAlt} /> Prêts</Nav.Link>
      </Nav>
    </div>

      {/* Placeholder for dashboard content */}
      <div className="content">
        <Container>
          <Row className="mt-5">
            <Col>
              <Card className="text-center">
                <Card.Body>
                  <FontAwesomeIcon icon={faMoneyCheckAlt} size="3x" className="mb-3" />
                  <Card.Title>Total Transferts</Card.Title>
                  <Card.Text>
                    {totalAmount} €
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
      <Card className="text-center">
        <Card.Body>
          <FontAwesomeIcon icon={faUsers} size="3x" className="mb-3" />
          <Card.Title>Total Utilisateurs</Card.Title>
          {totalUtilisateurs !== null ? (
            <Card.Text>
              {totalUtilisateurs}
            </Card.Text>
          ) : (
            <p>Chargement...</p>
          )}
        </Card.Body>
      </Card>
    </Col>
            
            <Col>
              <Card className="text-center">
                <Card.Body>
                  <FontAwesomeIcon icon={faSms} size="3x" className="mb-3" />
                  <Card.Title>Total SMS</Card.Title>
                  <Card.Text>
                  {smsBalance}
                  </Card.Text>
                  {smsBalance === 0 ? (
            <>
              <Button variant="primary" onClick={handleShowModal}>
                Recharger votre crédit
              </Button>
              <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Recharger votre crédit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form onSubmit={sendRequest}>
                    <Form.Group controlId="formShop">
                      <Form.Label></Form.Label>
                      <Form.Control
                        type="hidden"
                        name="shop"
                        value={formData.shop}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formAmount">
                      <Form.Label>Montant</Form.Label>
                      <Form.Control
                        type="number"
                        name="amount"
                        value={formData.amount}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formPhoneNumber">
                      <Form.Label>Numéro de téléphone</Form.Label>
                      <Form.Control
                        type="text"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                      Recharger
                    </Button>
                  </Form>
                </Modal.Body>
              </Modal>
            </>
          ) : (
            <Link to="/EnvoiSms">
              <Button variant="primary">Envoyer un SMS</Button>
            </Link>
          )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <Card>
                <Card.Body>
                  <ReactApexChart
                    options={chartOptions}
                    series={chartSeries}
                    type="bar"
                    height={350}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-5">
          <Col>
      <Card>
        <Card.Body>
          <Card.Title>Historique des Utilisateurs</Card.Title>
          {utilisateurs.length > 0 ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Nom et Prénom</th>
                  <th>Email</th>
                  <th>Solde</th>
                  <th>IBAN</th>
                  <th>Numéro de compte</th>
                  <th>Actions</th> {/* Nouvelle colonne pour les actions */}
                </tr>
              </thead>
              <tbody>
                {utilisateurs.map((utilisateur) => (
                  <tr key={utilisateur.id}>
                    <td>{utilisateur.nom} {utilisateur.prenom}</td>
                    <td>{utilisateur.email}</td>
                    <td>{utilisateur.solde} €</td>
                    <td>{utilisateur.iban}</td>
                    <td>{utilisateur.account_number}</td>
                    <td>
                      <Link to={`/update/${utilisateur.id}`}>
                        <Button variant="primary">Update</Button> {/* Lien vers la page de mise à jour avec l'ID */}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>Chargement...</p>
          )}
        </Card.Body>
      </Card>
    </Col>
            </Row>
        </Container>
      </div>
    </>
  );
}

export default Adminn;
