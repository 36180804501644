import React from "react";
import FAQ from "../components/faq";
import { useTranslation } from 'react-i18next';

function HomePage(){
    const { t, i18n } = useTranslation();

    return(
        <>
         <section class="hero-section--secondary position-relative z-0">
        <div class="animation position-absolute top-0 left-0 w-100 h-100 z-n1">
            <div class="animation__thumbs position-absolute">
                <img src={`${process.env.PUBLIC_URL}/assets/images/vector_men.png`} alt="vector" class="d-none d-xxxl-flex  previewShapeX "/>
            </div>
            <div class="animation__thumbs position-absolute">
                <img src={`${process.env.PUBLIC_URL}/assets/images/vector_men2.png`} alt="vector" class=" previewShapeRevX d-none d-xxl-flex"/>
            </div>
            <img src={`${process.env.PUBLIC_URL}/assets/images/vector_rocket.png`} alt="vector" class="position-absolute d-none d-xxxl-flex bottom-0 end-0 pe-12 pb-10 fadeInTopLeft"/>
        </div>
        <div class="container pt-20 mt-20">
            <div class="row pt-4 pt-lg-10 gy-12 gy-lg-0 justify-content-center align-items-center">
                <div class="col-lg-10 col-xxl-7">
                    <div class="hero-content text-center">
                    <h2 className="display-two mb-20 mb-lg-15" style={{ fontSize: '26px' }}>"{t('Acceuil.title')}"</h2>                        <div class="d-center flex-wrap gap-4 gap-lg-10">
                            <a href={`/${i18n.language}/signup`} class="cmn-btn secondary-alt fs-five nb4-xxl-bg gap-2 gap-lg-3 align-items-center py-2 px-5 py-lg-3 px-lg-6">{t('Acceuil.Start')} <i class="ti ti-trending-up"></i></a>
                            <a href={`/${i18n.language}/requestloan`} class="cmn-btn link  fs-five  gap-2 gap-lg-3 align-items-center "><i class="ti ti-arrow-narrow-right"></i> {t('Acceuil.Demander')}</a>
                        </div>
                    </div>
                    <div class="circle-text first d-center cus-z1 position-absolute d-none d-xxxl-flex">
                        <div class="text d-center">
                            <p class="fs-six">Trading Unlocking Diversified</p>
                        </div>
                        <img src={`${process.env.PUBLIC_URL}/assets/images/circle_star2.png`} alt="star" class="push_animat"/>
                    </div>
                    <div class="circle-text first d-center cus-z1 position-absolute end-0 top-0 d-none d-xxl-flex  pe-lg-10 pt-lg-10">
                        
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-sm-7 col-lg-5 col-xxl-4">
                    <div class="hero-video-area pseudo_element_before mb-10 mx-auto ms-auto ms-md-10 ms-lg-20 mt-8 mt-lg-10 justify-content-center justify-content-sm-end">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/hero_video.png`} alt="image"/>
                       
                        <div class="video_content position-absolute end-0 bottom-0 d-flex gap-3 z-1 ps-4">
                            <a href="" class="popup-video small box_10 btn-popup-animation position-absolute d-center rounded-circle">
                                <i class="fa-solid fa-play fs-four"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-sm-5 col-lg-5 col-xxl-8 d-none d-md-flex">
                    <div class="vector_thumbs position-relative z-0 ms-4 pt-8 pt-lg-10">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/hero_mobile.png`} class=" ms-8 ms-lg-15" alt="image"/>
                        <div class="position-absolute z-n1 start-0 top-0">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/hero_mobile_star.png`} alt="image" class="rotate time_dur"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="client_company_section py-10 p1-bg align-items-center justify-content-center">
        <div class="swiper client_company">
            <div class="swiper-wrapper align-items-center">
                <div class="swiper-slide text-center">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/company_logo.png`} alt="Client Logo"/>
                </div>
                <div class="swiper-slide text-center">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/company_logo2.png`} alt="Client Logo"/>
                </div>
                <div class="swiper-slide text-center">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/company_logo3.png`} alt="Client Logo"/>
                </div>
                <div class="swiper-slide text-center">
                    <img src="assets/images/company_logo4.png" alt="Client Logo"/>
                </div>
                <div class="swiper-slide text-center">
                    <img src="assets/images/company_logo5.png" alt="Client Logo"/>
                </div>
                <div class="swiper-slide text-center">
                    <img src="assets/images/company_logo6.png" alt="Client Logo"/>
                </div>
                <div class="swiper-slide text-center">
                    <img src="assets/images/company_logo7.png" alt="Client Logo"/>
                </div>
            </div>
        </div>
    </div>
<div class="trustpilot-widget" data-locale="fr-FR" data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="66d987f4aa9668025cb40cb5" data-style-height="52px" data-style-width="100%">
  <a href="https://fr.trustpilot.com/review/myfinaon.online" target="_blank" rel="noopener">Trustpilot</a>
</div>
    <section class="why-trade s1-bg alt-color position-relative z-0">
        <div class="animation position-absolute top-0 left-0 w-100 h-100 z-n1">
            <img src={`${process.env.PUBLIC_URL}/assets/images/sun.png`} alt="vector" class="position-absolute push_animat"/>
            <img src={`${process.env.PUBLIC_URL}/assets/images/star.png`} alt="vector" class="position-absolute  d-xxxl-flex previewSkew"/>
        </div>
        <div class="container">
            <div class="row gy-3 gy-lg-0 justify-content-center">
                <div class="col-sm-7 col-lg-6 col-xxl-5 order-2 order-lg-0">
                    <div class="why-trade__thumbs h-100 d-flex align-items-end ps-20 ps-sm-5 ps-lg-0">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/why_trade.png`} alt="Imgae"/>
                    </div>
                </div>
                <div class="col-lg-6 col-xxl-7">
                    <div class="row pt-120 pb-120">
                        <div class="col-xxl-6 offset-xxl-2">
                            <div class="why-trade__part">
                                <span class="heading fs-five">{t('Acceuil.Whytrade')}</span>
                                <h3 class="mb-3 mt-5">{t('Acceuil.trade')}</h3>
                                <p> {t('Acceuil.trade1')} </p>
                                <a href={`/${i18n.language}/signup`} class="cmn-btn link secondary-link fs-six-up  gap-2 gap-lg-3 align-items-center mt-5"> Learn more <i class="ti ti-arrow-narrow-right fs-four"></i></a>
                            </div>  
                        </div>  
                        <div class="col-xxl-12 mt-7 mt-md-8 mt-xxl-3">
                            <div class="why-trade__part d-flex align-items-center">
                                <div class="vector d-none d-xxl-flex px-xxl-15">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/trade_vector.png`} alt="Image" class="max-xxl-un"/>
                                </div>
                                <div class="content">
                                    <h3 class="mb-3"> {t('Acceuil.trade2')} </h3>
                                    <p> {t('Acceuil.trade3')} </p>
                                    <a href={`/${i18n.language}/requestloan`} class="cmn-btn link secondary-link fs-six-up  gap-2 gap-lg-3 align-items-center mt-5"> Learn more <i class="ti ti-arrow-narrow-right fs-four"></i></a>    
                                </div>
                            </div>  
                        </div>
                    </div>  
                </div>
            </div>
        </div>
    </section>
    
    <section class="worldwide alt-color p1-bg pseudo_element_after">
        <div class="animation vector position-absolute top-0 left-0 w-100 h-100 z-n1">
            <img src={`${process.env.PUBLIC_URL}/assets/images/cross.png`} alt="vector" class="position-absolute push_animat ms-lg-20 ps-4 top-0 pt-120 mt-20 start-0 d-none d-xxxl-flex"/>
            <img src={`${process.env.PUBLIC_URL}/assets/images/star.png`} alt="vector" class="position-absolute push_animat end-0 top-0 mt-20 pt-xl-15 me-xl-20 pe-10 d-none d-md-flex"/>
            <img src={`${process.env.PUBLIC_URL}/assets/images/vector2.png`} alt="vector" class="position-absolute bottom-0 start-0 d-none d-xxxl-flex"/>
            <img src={`${process.env.PUBLIC_URL}/assets/images/vector_rocket2.png`} alt="vector" class="position-absolute d-none d-xxxl-flex bottom-0 end-0  pe-12 pb-10 fadeInTopLeft"/>
            <img src={`${process.env.PUBLIC_URL}/assets/images/cross2.png" alt="vector`} class="position-absolute push_animat ms-lg-20 ps-4 top-0 start-50 pt-120 mt-20 d-none d-xxxl-flex"/>
        </div>
        <div class="container position-relative">
            <div class="row gy-10 gy-lg-0 justify-content-center justify-content-lg-between align-items-end">
                <div class="col-lg-6 col-xxl-5 pt-xl-20">
                    <div class="trade_on__content me-xl-10 pt-120 pb-0 pb-lg-120">
                        <h3 class="mb-4"> {t('Acceuil.trade5')} </h3>
                        <p class="mx-ch"> {t('Acceuil.trade6')} </p>   
                        <ul class="list_divided d-flex flex-wrap gap-3 gap-lg-5 mt-5 mt-xxl-6">
                            <li class="d-flex align-items-center gap-3 fs-six-up"><i class="ti ti-circle-check s1-color fs-four"></i> {t('Acceuil.trade7')} </li>
                            <li class="d-flex align-items-center gap-3 fs-six-up"><i class="ti ti-circle-check s1-color fs-four"></i> {t('Acceuil.trade8')} </li>
                            <li class="d-flex align-items-center gap-3 fs-six-up"><i class="ti ti-circle-check s1-color fs-four"></i> {t('Acceuil.trade9')} </li>
                            <li class="d-flex align-items-center gap-3 fs-six-up"><i class="ti ti-circle-check s1-color fs-four"></i> {t('Acceuil.trade10')} </li>
                        </ul>
                        <div class="mt-8 mt-xxl-10">
                            <div class="counter-area d-flex gap-8 gap-xxl-10 ">
                                <div class="counter-part">
                                    <div class="counters d-flex">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/creditcard.png`}></img>
                                    </div>
                                </div>
                                <div class="counter-part">
                                    <div class="counters d-flex">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-8 col-sm-7 col-lg-6 col-xxl-5">
                    <div class="worldwide_thumb d-flex ms-lg-15 ms-xl-0">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/worldwide_thumb.png`} class="max-xl-un" alt="Image"/>
                    </div>
                </div>
            </div>
        </div>
    </section>
  <FAQ/>
  <div class="swiper-pagination mt-8 mt-md-10 mt-lg-15"></div>

        </>
    )
}

export default HomePage;
