import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import Card from 'react-bootstrap/Card';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useDisclosure } from "@chakra-ui/react";
import mastercardLogo from "./img/mastercard.png";
import {
    FaWallet,
    FaHistory,
    FaBell,
    FaChartBar,
    FaReadme,
    FaArrowAltCircleUp,
    FaArrowAltCircleDown,
  } from "react-icons/fa";
function ContainerExample() {
    const { t , i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [user, setUser] = useState(null);
    const [transactions, setTransactions] = useState([]);
  
    useEffect(() => {
      const fetchUserData = async () => {
        try {
          const userId = localStorage.getItem('utilisateurId');
          if (!userId) {
            return window.location.href = '/login'; // Redirection si pas d'utilisateur ID
          }
  
          const userResponse = await axios.get(`https://finaon.onrender.com/profile/${userId}`);
          const userData = userResponse.data.utilisateur;
          setUser(userData);
  
          const accountNumber = userData.account_number;
          const transactionsResponse = await axios.get(`https://finaon.onrender.com/historique/${accountNumber}`);
          setTransactions(transactionsResponse.data);
  
          setIsLoading(false);
        } catch (error) {
          console.error('Erreur lors de la récupération des informations de l\'utilisateur ou des transactions:', error);
          setIsLoading(false);
        }
      };
  
      fetchUserData();
    }, []);
  
    const handleLogout = () => {
      localStorage.removeItem('token');
      localStorage.removeItem('utilisateurId');
      window.location.href = '/login';
    };
    const handleSendClick = () => {
        window.location.href =  `/${i18n.language}/transfert`;  // Replace 'https://example.com' with your target URL
      };
    
  
    if (!localStorage.getItem('token')) {
      return window.location.href = '/login';
    }
  return (
    <Container style={{ marginTop: '10%' }} >
      <Row>
      <Col md={2} className="d-none d-md-block sidebar" >
          <ListGroup style={{ marginTop: '55%' }}>
            <ListGroupItem action onClick={onOpen}>
              <FaWallet />   &nbsp;&nbsp;&nbsp; Wallet
            </ListGroupItem>
            <ListGroupItem action>
              <FaHistory />&nbsp;&nbsp;&nbsp; Historique
            </ListGroupItem>
            <ListGroupItem action>
              <FaBell />&nbsp;&nbsp;&nbsp; Notifications
            </ListGroupItem>
            <ListGroupItem action>
              <FaChartBar />&nbsp;&nbsp;&nbsp; Analytics
            </ListGroupItem>
          </ListGroup>
        </Col>
        <Col sm={9}>
        <Card className="mb-4 card-background">
            <Card.Body>
              <Card.Title>Active loan</Card.Title>
              <Card.Text>
              Vous devez déposer le montant de 100 € avant le 31.02.25
              </Card.Text>
              {user ? (
              <Row>
                <Col>
                  <h2>{user.solde} €</h2>
                  <p>Balance</p>
                </Col>
                <Col>
                  <h2>{user.credit}</h2>
                  <p>Prête</p>
                </Col>
              </Row>
              ) : (
                <p>Loading...</p>
              )}
              <Button
                style={{ backgroundColor: "#D6F60C", color: "black" }}
                className="me-2 cmn-btn fw-bold py-2 px-2 px-sm-3 px-lg-4 align-items-center gap-1"
                onClick={handleSendClick} >
                Envoyer
              </Button>
              <Button
                variant="dark"
                className="me-2 cmn-btn fw-bold py-2 px-2 px-sm-3 px-lg-4 align-items-center gap-1"
              >
                Ajouter
              </Button>
            </Card.Body>
          </Card>
          <Card className="mb-3">
            <Card.Body>
              <Button variant="primary" className="mb-2">
              Reporter le prêt
              </Button>
              <ListGroup>
                {transactions.map((transaction) => (
                  <ListGroupItem key={transaction.id} className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      {transaction.from_user_id === user.account_number ? (
                        <>
                          <FaArrowAltCircleUp className="me-2" size={20} />
                          {transaction.beneficiaire}
                        </>
                      ) : (
                        <>
                          <FaArrowAltCircleDown className="me-2" size={20} />
                          {transaction.expediteur}
                        </>
                      )}
                    </div>
                    <div>
                      <span className="amount">
                        {transaction.from_user_id === user.account_number ? "-" : "+"} {Math.abs(transaction.amount)} €
                      </span>
                    </div>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col>
        <Card className="mb-4 card-background">
            <Card.Body className="card-body">
              <Card.Title>Credit Card</Card.Title>
              <Card.Text></Card.Text>
              <Card.Text>**** **** **** 9636</Card.Text>
              <Card.Text>
                <img
                  src={mastercardLogo}
                  alt="Mastercard Logo"
                  className="mastercard-logo me-2"
                /> Exp: 12/28
              </Card.Text>
            </Card.Body>
          </Card>
          {user && (
            <Card>
              <Card.Body>
                <Card.Title>Vos Information Bancaire</Card.Title>
                <ListGroup>
                  <ListGroupItem>Titulaire du compte: {user.nom} {user.prenom}</ListGroupItem>
                  <ListGroupItem>IBAN: {user.iban}</ListGroupItem>
                  <ListGroupItem>BIC: {user.bic}</ListGroupItem>
                </ListGroup>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default ContainerExample;