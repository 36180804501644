import React, { useState } from "react";
import {
    Box,Flex,Text,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Button,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
  } from "@chakra-ui/react";
import axios from 'axios'; // Assurez-vous d'importer axios ou votre bibliothèque HTTP préférée
import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Swal from "sweetalert2";
import { BeatLoader, PropagateLoader, ClockLoader, PulseLoader } from 'react-spinners';
import { useNavigate } from "react-router-dom";


function RequestLoan(params) {
    const { t, i18n } = useTranslation();

    const [loanAmount, setLoanAmount] = useState(0);
    const [interestRate, setInterestRate] = useState(0);
    const [loanTerm, setLoanTerm] = useState(0);
    const [monthlyPayment, setMonthlyPayment] = useState(0);
    const [totalInterest, setTotalInterest] = useState(0);
    const [totalAmountToPay, setTotalAmountToPay] = useState(0);
    const [amortizationTable, setAmortizationTable] = useState([]);
    const [formData, setFormData] = useState({
      fullName: '',
      email: '',
      phone: '',
      loanAmount: '',
    });
  
    const handleChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    };
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();


    const handleSubmitLoanRequest = async (e) => {
      e.preventDefault();
      setIsLoading(true);
  
      try {
        // Remplacez par votre URL et configuration d'axios pour l'envoi des données au backend
        const response = await axios.post('https://finaon.onrender.com/api/loan-request', formData);
        console.log('Réponse du serveur:', response.data);
        Swal.fire({
            icon: "success",
            title: "Demande Envoyer",
            text: "Vous allez recevoir une reponse dans peut de temps.",
          });
          setTimeout(() => {
            navigate(`/${i18n.language}/home`);
            setIsLoading(false);
          }, 5000); 
        // Gérer la réponse du serveur ici (par exemple, afficher un message de succès)
      } catch (error) {
        console.error('Erreur lors de l\'envoi de la requête:', error);
        // Gérer les erreurs ici (par exemple, afficher un message d'erreur)
      }
    };
  
    const calculateMonthlyPayment = () => {
      const monthlyInterestRate = interestRate / 1200; // Convertir le taux d'intérêt annuel en taux mensuel
      const termInMonths = loanTerm * 12; // Convertir la durée du prêt en mois
  
      const monthlyPayment =
        (loanAmount * monthlyInterestRate) /
        (1 - Math.pow(1 + monthlyInterestRate, -termInMonths));
  
      setMonthlyPayment(monthlyPayment.toFixed(2)); // Fixer le paiement mensuel à deux décimales
    };
  
    const generateAmortizationTable = () => {
      const monthlyInterestRate = interestRate / 1200; // Convertir le taux d'intérêt annuel en taux mensuel
      const termInMonths = loanTerm * 12; // Convertir la durée du prêt en mois
  
      let remainingBalance = loanAmount;
      let amortizationData = [];
      let totalInterest = 0;
  
      for (let i = 1; i <= termInMonths; i++) {
        const interestPayment = remainingBalance * monthlyInterestRate;
        const principalPayment = monthlyPayment - interestPayment;
  
        remainingBalance -= principalPayment;
  
        amortizationData.push({
          month: i,
          payment: monthlyPayment.toFixed(2),
          principal: principalPayment.toFixed(2),
          interest: interestPayment.toFixed(2),
          balance: remainingBalance.toFixed(2),
        });
  
        totalInterest += parseFloat(interestPayment.toFixed(2));
      }
  
      setAmortizationTable(amortizationData);
      setTotalInterest(totalInterest.toFixed(2));
      setTotalAmountToPay((parseFloat(loanAmount) + totalInterest).toFixed(2));
    };
  
    const handleCalculate = () => {
      calculateMonthlyPayment();
      generateAmortizationTable();
    };

    return(
        <HelmetProvider>

        <>
         <Helmet>
          <title>Offre de prêt</title>
          <meta name="description" content="Laissez-vous inspirer par notre collection de meubles et décors. Chaque pièce est choisie pour apporter charme et fonctionnalité à votre intérieur, créant un espace où il fait bon vivre" />
        </Helmet>
        <section class="banner-section  pt-120 pb-120">
        <div class="container mt-10 mt-lg-0 pt-15 pt-lg-20 pb-5 pb-lg-0">
            <div class="row">
                <div class="col-12 breadcrumb-area ">
                    <h2 class="mb-4">Demande de prêt</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0">
                            <li class="breadcrumb-item"><a href="index-2.html">Maison</a></li>
                            <li class="breadcrumb-item ms-2 ps-7 active" aria-current="page"><span>Demande de prêt</span></li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </section>

    <section class="contact nb4-bg pt-120 pb-120">
        <div class="container ">
            <div class="row gy-18 justify-content-between">
                <div class="col-12 col-lg-5 col-xl-5">
                    <div class="contact__form alt_form px-4 px-lg-8">
                        <h3 class="contact__title mb-7 mb-md-10 mb-lg-15" style={{color: 'white'}}> {t('loan.calcule')}</h3>
                        <div class="d-flex gap-3 gap-sm-5 gap-lg-8 flex-column">
                            <div class="row gap-3 gap-sm-0">
                                <div class="col-sm-6">
                                    <div class="single-input">
                                    <label>{t('loan.loanAmountLabel')}</label>
                                        <input type="number"
              value={loanAmount}
              onChange={(e) => setLoanAmount(parseFloat(e.target.value))}
              required />
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="single-input">
                                    <label>{t('loan.interestRateLabel')}</label>
                                        <input type="number" class="fs-six-up" 
              value={interestRate}
              onChange={(e) => setInterestRate(parseFloat(e.target.value))}
              required placeholder="Nom de famille"/>
                                    </div>
                                </div>
                            </div>
                            <div class="row gap-3 gap-sm-0 ">
                                <div class="col-sm-6">
                                    <div class="single-input">
                                        <label>{t('loan.loanTermLabel')}</label>
                                        <input type="number" class="fs-six-up"  
              value={loanTerm}
              onChange={(e) => setLoanTerm(parseFloat(e.target.value))} />
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                <button onClick={handleCalculate} class="cmn-btn py-3 px-5 px-lg-6 mt-8 mt-lg-10 d-flex ms-auto" name="submit" id="submit">{t('loan.calculateButton')}<i class="bi bi-arrow-up-right"></i><span></span></button>
                                
                                </div>
                            </div>
    
                        </div>
                        <br/>
                       
     
                        <span id="msg">

                        {monthlyPayment > 0 && (
            <>
              <Heading as="h3" size="md" mb="2">
              {t('loan.monthlyPayment')} {monthlyPayment} €
              </Heading>
              <Heading as="h4" size="sm" mb="2">
              {t('loan.totalInterest')} {totalInterest} €
              </Heading>
              <Heading as="h4" size="sm" mb="2">
              {t('loan.totalAmountToPay')} {totalAmountToPay} €
              </Heading>
            </>
          )}
                            <br/>
    
                            </span> 
       
                    </div><br/>
                </div>
                <PropagateLoader color="#81e80e" loading={isLoading} size={20} />
                <div class="col-12 col-lg-7 col-xl-6">
                    <form onSubmit={handleSubmitLoanRequest} class="contact__form alt_form px-4 px-lg-8">
                        <h3 class="contact__title mb-7 mb-md-10 mb-lg-15" style={{color: 'white'}}>{t('loan.requestloan')}</h3>
                        <div class="d-flex gap-3 gap-sm-5 gap-lg-8 flex-column">
                            <div class="row gap-3 gap-sm-0">
                                <div class="col-sm-6">
                                    <div class="single-input">
                                        <input type="text" class="fs-six-up" name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              placeholder="Nom de famille" required />
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="single-input">
                                        <input type="email" class="fs-six-up" name="email"
              value={formData.email}
              onChange={handleChange} placeholder="Email" required />
                                    </div>
                                </div>
                            </div>
                            <div class="row gap-3 gap-sm-0 ">
                                <div class="col-sm-6">
                                    <div class="single-input">
                                        <input type="text" class="fs-six-up" name="phone"
              value={formData.phone}
              onChange={handleChange}  placeholder="Telephone" required />
                                    </div>
                                </div> <br/>
                                <div class="col-sm-6">
                                    <div class="single-input">
                                        <input type="text" class="fs-six-up" name="loanAmount"
              value={formData.loanAmount}
              onChange={handleChange}  placeholder="Montant" required />
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                        <span id="msg"></span> 
                        <button type="submit" class="cmn-btn py-3 px-5 px-lg-6 mt-8 mt-lg-10 d-flex ms-auto">{t('loan.submitLoanRequestButton')}<i class="bi bi-arrow-up-right"></i><span></span></button>
                    </form><br/>
                </div><br/>

            </div>
        </div>
    </section>
        
        </>
        </HelmetProvider>

    );
    
}

export default RequestLoan;